.at-carousel {
	width: 100%;
	position: relative;
	display: flex;
	overflow: hidden;
	padding-bottom: 30px;
}

.at-carousel-bullet-container {
	position: absolute;
	height: 30px;
	width: 100%;
	padding-top: 5px;
	bottom: 0px;
	text-align: center;
}

.at-carousel-bullet {
	display: inline-block;
	position: relative;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	margin: 3px;
	background: #DDDDDD;
	background: rgba(130,130,130,0.4);
	cursor: pointer;
	cursor: hand;
	box-shadow: 0 1px 1px rgba(255,255,255,0.6), inset 0 1px 1px rgba(0,0,0,0.3);
}

.at-carousel-bullet-active {
	
}

.at-carousel-bullet-active:after {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	top: 3px;
	left: 3px;
	border-radius: 50%;
	background: rgba(255,255,255,0.8);
}

.at-carousel-slide {
	display: inline-block;
	flex: 1 0 100%;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.at-carousel-slide-hidden {
	display: none;
}

.at-carousel-arrow-left {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAKwwAACsMBNCkkqwAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMTAvMTkvMTJlCtGpAAABaElEQVR4nO3cS3HEMBRE0atU1oaURTCIwxiSh4NQBNIQcDDEfp/OVF8AKp3yR5IXHsD3GIP/3HmefI4xOI6jey632vedj+5JRGWIWoaoZYhahqhliFqGqGWIWoaoZYhahqhliFqGqGWIWoZENed8RIzTCplzPrZt+4oYqw0SiYAmSDQCGiAZCCiGZCGgEJKJgCJINgIKIBUISIZUISARUomAJEg1AhIgHQgIhnQhIBDSiYAgSDcCAiAKCLgJUUHADYgSAi5C1BBwAaKIAIGPD1H9GbLWer5er5+Mydzp0hVRxFy+tdQwt54RJczth10FE/LWUsCEvX67MaHrSCcmfEHswqSs7B2YtC1KNSZ1r1WJSd80VmFKdr8VmLJtfDam9DySiSk/WGVhWk6IGZi2o240pvXMHolp//iw1npGjNMOicoQtQxRyxC1DFHLELUMUcsQtQxRyxC1DFHLELUMUettIIM3+XXbL64qrlAHZEgkAAAAAElFTkSuQmCC);
	position: absolute;
	width: 50px;
	height: 100px;
	cursor: pointer;
	cursor: hand;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 909;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	display: none;
}

.at-carousel-arrow-right {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAKwwAACsMBNCkkqwAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMTAvMTkvMTJlCtGpAAABXklEQVR4nO3cvW2EQBRF4TPWxpTkwDXQw1IS7oEqXBINsKlTM+/nenVPDuKTYOZNwgC+xhj8567r4jHGYN/37meZats2ProfIipD1DJELUPUMkQtQ9QyRC1D1DJELUPUMkQtQ9QyRC1D1DLkd+u6PiPuM1MIZFmWz25M2KvVjQn9Rjox4R97FyZl1erApC2/1ZjUfaQSk74hVmFKdvYKTNmIko0pnbUyMeVDYxamZfrNwLSN8dGY1vNIJKb9YBWFaYdADEYCAvMYGQjMYaQgcB8jB7mbHOQ8z5/jOL7/ep0U5C4ChCAzCBCBzCJAABKBgGZIFAIaIZEIaIJEI6ABkoGAYkgWAgohmQgogmQjoABSgYBkSBUCEiGVCEiCVCMgAdKBgGBIFwICIZ0ICIJ0IyAI0o0AgYNVVIaoZYhahqhliFqGqGWIWoaoZYhahqhliFqGqGWIWm8DGbzJr9teHYWuULzgkDAAAAAASUVORK5CYII=);
	position: absolute;
	width: 50px;
	height: 100px;
	cursor: pointer;
	cursor: hand;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 909;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	display: none;
}