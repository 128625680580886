
@font-face { font-family: RobotoRegular; src: url(./third-party/fonts/roboto.regular.ttf); }
@font-face { font-family: RobotoLight; src: url(./third-party/fonts/roboto.light.ttf); }
@font-face { font-family: RobotoMedium; src: url(./third-party/fonts/roboto.medium.ttf); }
@font-face { font-family: LatoSemibold; src: url(./third-party/fonts/Lato-Semibold.ttf); }

html {
	margin: 0px;
	padding: 0px;
	border: 0px;
}

body {
	font-family: RobotoRegular, Helvetica, Arial, Verdana, sans-serif;
	font-size: 14px;
	line-height: 17px;
	background-color: #F2F2F2;
	margin: 0px;
	padding: 0px;
}

* {
	/* border-box allows padding from inside. */
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline-style: none;
}


/** @pragmamark - Common - Input Fields **/

.app-input-group {
	position: relative;
}

.app-input-group label {
	position:absolute;
	color: #999999;
	font-size: 13px;
	line-height: 16px;
	font-weight:normal;
	left: 10px;
	top: 7px;
	transition:0.2s ease all;
	-moz-transition:0.2s ease all;
	-webkit-transition:0.2s ease all;
	cursor: text;
}

.app-input-group input:focus ~label, .app-input-group input:valid ~label, .app-input-group input[readonly] ~label, .app-input-group select:focus ~label, .app-input-group select:valid ~label {
	left: 0px;
	top: -16px;
	color: #444444;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
}

.app-input-textfield {
	width: 100%;
	height: 30px;
	font-size: 13px;
	line-height: 16px;
	padding: 0px 10px 0px 10px;
}

.app-input-button {
	display: inline-block;
	width: 100%;
	height: 30px;
	line-height: 23px;
	background-color: #AAAAAA;
	background-position: 50% 50%;
	background-size: 11px;
	background-repeat: no-repeat;
	border-bottom: 2px solid #969696;
	border-radius: 3px;
	transition: background-color 0.5s ease;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 13px;
	color: #EFEFEF;
	font-family: RobotoMedium;
	padding: 0px 10px 0px 10px;
	outline-style: none;
}

.app-input-button:hover {
	border-bottom-color: #2d6d91;
	background-color: #3b88b6;
}
.app-input-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
	border-bottom: 0px;
}

.app-input-button-quick-link {
	width: 100%;
	height: 26px;
	font-size: 13px;
	line-height: 20px;
	padding: 3px 6px 3px 6px;
	cursor: pointer;
	box-shadow: 0 1px 1px rgba(0,0,0,0.3), inset 0 1px 1px rgba(255,255,255,0.6);
	border: 1px solid #EEEEEE;
	text-align: center;
}

.app-input-button-quick-link:hover {
	border-bottom-color: #DDDDDD;
	background-color: #ECECEC;
}
.app-input-button-quick-link:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
	border-bottom: 0px;
}

/** @pragmamark - Common - Page Style **/

.content-box {
	width: 100%;
	max-width: 900px;
	min-width: 300px;
	margin: 0px auto 0px auto;
	padding: 0px 10px 0px 10px; 
}

.page-heading {
	width: 100%;
	color: #444444;
	text-align: center;
	font-size: 20px;
	line-height: 24px;
	font-weight: bold;
	text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
	padding: 10px 0px 10px 0px;
}

.section-heading {
	font-size: 16px;
	line-height: 20px;
	font-family: RobotoMedium;
}

.horizontal-line {
	height: 2px;
	min-height: 2px;
	max-height: 2px;
	background-repeat: repeat-x;
	background-position: center center;
	background-image: url(./images/horizontal-line-background.png);
}

.vertical-line {
	width: 2px;
	min-width: 2px;
	height: 100%;
	background-repeat: repeat-y;
	background-position: center center;
	background-image: url(./images/vertical-line-background.png);
}


/** @pragmamark - Misc **/

.email-aurvan {
	display: inline-block;
	width: 64px;
	height: 17px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.email-aurvan-support {
	background-image: url(./images/email-aurvan-support.png);
	width: 144px;
}

.email-aurvan-sales {
	background-image: url(./images/email-aurvan-sales.png);
	width: 128px;
}

.email-aurvan-feedback {
	background-image: url(./images/email-aurvan-feedback.png);
	width: 154px;
}

.email-aurvan-info {
	background-image: url(./images/email-aurvan-info.png);
	width: 115px;
}



/** @pragmamark - Common - Ceiling Panel **/

.app-input-button-square {
	width: 30px;
	height: 30px;
	line-height: 20px;
}

.square-button-facebook {
	background-image: url(./third-party/images/Facebook.png);
	margin-right: 5px;
}

.square-button-twitter {
	background-image: url(./third-party/images/Twitter.png);
	margin-right: 5px;
}

.square-button-user-info {
	background-image: url(./third-party/images/User.png);
	width: 32px;
	height: 32px;
	border-radius: 16px;
}

.square-button-hamburger {
	background-image: url(./third-party/images/Hamburger.png);
	margin-top: 30px;
	display: none;
	transition: background-color 0.5s ease;
}

.profile-bar {
	height: 60px;
	min-height: 60px;
	max-height: 60px;
	border-bottom: 1px solid #EBEBEB;
	background-color: transparent;
}

.social-bar {
	display: block;
	padding-top: 10px;
	float: left;
}

.user-info-bar {
	display: block;
	padding-top: 9px;
	float: right;
}

.navigation-bar {
	height: 90px;
	min-height: 90px;
	max-height: 90px;
	background-color: #FFFFFF;
	border-bottom: 1px solid #EBEBEB;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
}

.banner-bar {
	display: inline-block;
}

.banner {
	margin-top: 25px;
	
	width: 370px;
	height: 40px;
	background-repeat: no-repeat;
	
	font-family: LatoSemibold;
	font-size: 40px;
	line-height: 40px;
	color: rgba(59, 136, 182, 0.8);
	text-shadow: 1px 4px 6px #FFFFFF, 0 0 0 #000, 1px 4px 6px #FFFFFF;
}
@media screen and (max-width: 500px) {
	.banner {
		width: 290px;
		font-size: 30px;
	}
}

/* Don't show shadows when selecting text */
::-moz-selection { background: #66B9FF; color: #FFFFFF; text-shadow: none; }
::selection { background: #66B9FF; color: #FFFFFF; text-shadow: none; }

.navigation-content-bar {
	display: block;
	float: right;
}

.navigation-link {
	font-family: RobotoMedium;
	color: rgb(59, 136, 182);
	background-color: #FFFFFF;
	text-decoration: none;
	margin: 32px 0px 0px 20px;
	padding: 4px 8px 4px 8px;
	display: inline-block;
	border-radius: 3px;
	cursor: pointer;
	/* text-shadow: 1px 1px 0px rgba(59, 136, 182, 0.3); */
}
.navigation-link:after {
	content: "";
	display: block;
	margin: auto;
	height: 2px;
	width: 0px;
	background-color: transparent;
	transition: width .2s ease, background-color .2s ease;
}
.navigation-link:hover:after {
	width: 100%;
	background-color: rgb(59, 136, 182);
}
.navigation-link:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}
.navigation-link-selected:after {
	width: 100%;
	background-color: rgb(59, 136, 182);
}
.navigation-dropdown {
	position: relative;
	display: inline-block;
}
.navigation-dropdown-selected .navigation-dropdown-content {
	display: block;
}
.navigation-dropdown-selected .square-button-hamburger {
	border-bottom-color: #2d6d91;
	background-color: #3b88b6;
}
.navigation-dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	right: 0;
	border: 1px solid #DDDDDD;
}
.navigation-dropdown-link {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	cursor: pointer;
}
.navigation-dropdown-link:hover {
	background-color: #f1f1f1
}

@media screen and (max-width: 900px) and (min-width: 500px) {
	.square-button-hamburger {
		display: inline-block;
	}
	.navigation-link {
		display: none;
	}
}

@media screen and (max-width: 500px) {
	.square-button-hamburger {
		display: inline-block;
	}
	.navigation-link {
		display: none;
	}
}


/** @pragmamark - Footer Bar Style **/

.footer-bar {
	font-size: 12px;
	line-height: 14px;
	height: 60px;
	min-height: 60px;
	max-height: 60px;
	text-align: center;
	background-color: #AAAAAA;
	color: #FFFFFF;
	box-shadow: inset 0 3px 6px 0 rgba(0,0,0,0.16);
	padding-top: 23px;
}
@media screen and (max-width: 500px) {
	.footer-bar {
		padding-top: 17px;
	}
}
.footer-bar-link {
	text-decoration: none;
	color: #FFFFFF;
	cursor: pointer;
}


/** @pragmamark - Home **/

.home-slide-container {
	width:90%;
	height:auto;
	margin-left:auto;
	margin-right:auto;
	display: block;
	overflow-y: hidden;
	overflow-x: auto;
}

.home-slide-row {
	display: table;
	width: 100%;
}

.home-slide-column {
	width: 50%;
	padding: 0px 15px 0px 15px;
	display: table-cell;
	vertical-align: top;
}

.home-slide-heading {
	font-size: 33px;
	line-height: 33px;
	color: #505050;
}

.home-slide-subheading {
	font-size: 16px;
	line-height: 20px;
	color:#3b88b6;
}

.home-slide-description {
	font-family: RobotoLight;
	font-size: 13px;
	line-height: 20px;
	color:#919191;
	text-align: justify;
}

.home-slide-description-bottom-padding {
	display: none;
}

.home-slide-image {
	min-height: 250px;
	border: 1px solid #DDDDDD;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-shadow: -3px 3px 6px 0px rgba(0,0,0,0.16);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	margin: 0px 0px 6px 0px;
}

.home-slide-sqlight {
	display: none;
}

.home-slide-solitaire {
	display: block;
}

@media screen and (max-width: 900px) {
	.home-slide-container {
		width:100%;
	}
	.home-slide-column  {
		width: 100%;
		display: block;
	}
	.home-slide-description-bottom-padding {
		display: block;
	}
}

.showcase-row {
	clear: both;
	display: table;
	width: 100%;
	height: 100%;
}

.showcase-column {
	width: 32%;
	padding: 0px 10px 0px 10px;
	display: table-cell;
	vertical-align: top;
}

.showcase-column-vertical-line {
	width: 2%;
	height: 100%;
	text-align: center;
}

.showcase-column .section-heading {
	text-align: center;
}

.showcase-column-border-bottom {
	padding: 15px 0px 15px 0px;
	display: none;
}

.showcase-description {
	text-align: justify;
}

.showcase-text {
	text-indent: 30px;
	color:#666666;
	padding: 0px 0px 5px 0px;
}

.showcase-link {
	text-decoration: none;
	color: rgb(59, 136, 182);
	cursor: pointer;
	text-decoration: underline;
}

.showcase-product-row {
	display: table;
	width: 100%;
	text-align: justify;
	cursor: pointer;
	cursor: hand;
	border: 1px dashed #BBBBBB;
	padding: 8px 8px 8px 8px;
}

.showcase-product-row:hover {
	background-color: #DEDEDE;
}

.showcase-product-column-icon {
	display: table-cell;
	vertical-align: middle;
	width: 64px;
}

.showcase-product-column-description {
	display: table-cell;
	vertical-align: middle;
	padding: 0px 0px 0px 0px;
}

.showcase-product-icon {
	vertical-align: middle;
	height: 48px;
	width: 48px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.showcase-product-title {
	left: 80px;
	font-size: 15px;
}

.showcase-product-text {
	left: 80px;
	font-size: 13px;
	color:#666666;
	margin-top: 2px;
}

@media screen and (max-width: 900px) {
	.showcase-column  {
		width: 100%;
		display: inline-block;
	}
	.showcase-column-border-bottom {
		display: block;
	}
	.showcase-column-vertical-line {
		display: none;
	}
}

.showcase-subscription-input-group {
	margin-top: 15px;
	border: 1px solid rgba(190, 190, 190, 1);
	border-radius: 3px;
}
.showcase-subscription-textfield {
	width: 70%;
	display: inline;
	border: 0px;
	border-radius: 3px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.showcase-subscription-button {
	width: 30%;
	display: inline;
	min-width: 70px;
	float: right;
	border: 0px;
	border-radius: 3px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}


/** @pragmamark - Search Product **/

.product-summary-container {
	display: inline-block;
}
.product-summary-image {
	display: block;
	float: left;
	width: 128px;
	height: 128px;
}
.product-summary-text {
	margin-left: 140px;
	text-align: justify;
}
#product-summary-links-horizontal {
	display: block;
	float: right;
	margin-top: 12px;
}
#product-summary-links-vertical {
	display: none;
	margin-top: 15px;
}
@media screen and (max-width: 500px) {
	.product-summary-text {
		display: inline-block;
		margin-left: 0px;
	}
	#product-summary-links-vertical {
		display: block;
	}
	#product-summary-links-horizontal {
		display: none;
	}
}

.product-summary-title {
	font-family: RobotoMedium;
	font-size: 17px;
	line-height: 20px;
}
.product-summary-description {
	font-family: RobotoLight;
	font-size: 14px;
	line-height: 18px;
}
.product-summary-link-button {
	color: #666666;
	width: 150px;
	height: 22px;
	line-height: 20px;
	background-color: #CCCCCC;
	text-align: center;
	cursor: pointer;
	cursor: hand;
	font-size: 12px;
	border: 1px solid #BBBBBB;
	margin-left: 12px;
	margin-bottom: 12px;
}
#product-summary-links-horizontal .product-summary-link-button {
	float: left;
}
#product-summary-links-vertical .product-summary-link-button {
	float: right;
}
.product-summary-link-button:hover {
	background-color: #BBBBBB;
	border: 1px solid #999999;
}
.product-summary-link-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}


/** @pragmamark - Support **/

.support-details-row {
	clear: both;
	display: table;
	width: 100%;
	height: 100%;
}

.support-details-column {
	width: 48%;
	padding: 0px 15px 0px 15px;
	display: table-cell;
}

.support-details-description {
	text-align: justify;
	padding: 5px 0px 0px 0px;
}

.support-details-column-vertical-line {
	display: table-cell;
	width: 4%;
	height: 100%;
	text-align: center;
}

.support-details-horizontal-line-container {
	display: table;
}

.support-details-separator-horizontal
, .support-details-border-bottom {
	padding: 15px 0px 15px 0px;
}

.support-details-border-bottom {
	display: none;
}

@media screen and (max-width: 500px) {
	.support-details-column  {
		width: 100%;
		display: inline-block;
	}
	.support-details-horizontal-line-container {
		display: none;
	}
	.support-details-column-vertical-line {
		display: none;
	}
	.support-details-border-bottom {
		display: block;
	}
}


/** @pragmamark - Portfolio **/

.portfolio-details-row {
	clear: both;
	display: table;
	width: 100%;
	height: 100%;
}

.portfolio-details-column {
	width: 48%;
	padding: 0px 15px 0px 15px;
	display: table-cell;
}

.portfolio-details-column-about-us {
	width: 100%;
}

.portfolio-details-description {
	text-align: justify;
	padding: 5px 0px 0px 0px;
}

.portfolio-details-border-bottom {
	padding: 15px 0px 15px 0px;
	display: none;
}

.portfolio-details-border-bottom-about-us {
	display: block;
}

.portfolio-details-column-vertical-line {
	display: table-cell;
	width: 4%;
	height: 100%;
	text-align: center;
}

@media screen and (max-width: 500px) {
	.portfolio-details-column  {
		width: 100%;
		display: inline-block;
	}
	.portfolio-details-border-bottom {
		display: block;
	}
	.portfolio-details-column-vertical-line {
		display: none;
	}
}


/** @pragmamark - Login Customer **/

.login-customer-container {
	width: 45%;
	min-width: 300px;
	margin: 0px auto 0px auto;
}

.login-customer-input-container {
	padding: 8px 0px 8px 0px;
	width: 100%;
	text-align: center;
}


/** @pragmamark - Product Details (SQLight, Solitaire) **/

.product-details-row {
	clear: both;
	display: table;
	width: 100%;
	height: 100%;
}

.product-details-row-dark {
	background-color: #DDDDDD;
}

.product-details-row-dark > .product-details-column {
	float: left;
}

.product-details-row-light {
	background-color: transparent;
}

.product-details-row-light > .product-details-column {
	float: right;
}

.product-details-column {
	width: 48%;
	padding: 15px;
	display: table-cell;
	vertical-align: top;
}

.product-details-description {
	padding: 0px 15px 0px 15px;
	text-align: justify;
}

.product-details-border-bottom {
	padding: 15px 0px 15px 0px;
	display: none;
}

.product-details-column-vertical-line {
	display: table-cell;
	width: 4%;
	height: 100%;
	text-align: center;
}

.product-awards-image-container {
	width: 100%;
	height: auto;
	text-align: left;
}

.product-award-link {
	text-decoration: none;
	width: 200px;
	height: 100px;
	border: 1px solid #DDDDDD;
	margin: 5px;
}

@media screen and (max-width: 500px) {
	.product-details-column  {
		width: 100%;
		display: inline-block;
	}
	.product-details-border-bottom {
		display: block;
	}
	.product-details-column-vertical-line {
		display: none;
	}
	.product-awards-image-container {
		text-align: center;
	}
}


/** @pragmamark - Product Details - SQLight **/

.sqlight-screenshot {
	height: 211px;
	width: 320px;
	display: inline-block;
	background-repeat: no-repeat;
	cursor: pointer;
}


/** @pragmamark - Product Details - Solitaire **/

.solitaire-screenshot {
	height: 230px;
	width: 320px;
	display: block;
	background-repeat: no-repeat;
	cursor: pointer;
}
